import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Slider} from "../../components/Slider";
import {useParams} from "react-router";

export function Catalog(props) {

    const params = useParams();

    const [detailId, setDetailId] = useState(false);
    const [marginDetailTop, setMarginDetailTop] = useState(0)
    const [touchStartY, setTouchStartY] = useState(0);
    const [isMovedToSection,setMovedState] = useState(false);
    useEffect(()=>{
        if(detailId !== false) {
            document.getElementById("App").classList.add("no-touch");
        } else {
            document.getElementById("App").classList.remove("no-touch");
        }
        console.log("useEffect");
        console.dir(params);
        if((isMovedToSection == false) && (props.data != false) && (params.sectionId != undefined)) {
            console.log("moveto="+params.sectionId)
            moveToSection(params.sectionId)
        }
    });
    function handleTouchStart(touchStartEvent) {
        touchStartEvent.preventDefault();
        const touch = touchStartEvent.touches[0];
        setTouchStartY(touch.pageY);

        console.dir(touchStartEvent);

    }
    function handleTouchMove(touchMoveEvent) {
        touchMoveEvent.preventDefault();
        const touch = touchMoveEvent.touches[0];
        let y = touch.pageY-touchStartY;
        if(y < -20) {y = -20}
        //if(x < -60) {x = -60}
        setMarginDetailTop(y);

        console.dir(touchMoveEvent);

    }
    function handleTouchEnd() {
        if(marginDetailTop > 200) {
            console.log("touch close");
            openDetail(false);
        }

        setMarginDetailTop(0);
    }


    console.dir("Catalog:start");
    console.dir(props);
    let elements = props['data']['elements'];
    console.dir(elements);

    function openDetail(itemId) {
        setDetailId(itemId);
    }

    let elementDetailStyle = {
        transform: `translateY(${marginDetailTop}px)`
    }

    function moveToSection(sectionId) {
        let section = document.getElementById("section"+sectionId);
        let headerOffset = 92;
        let elementPosition = section.getBoundingClientRect().top;
        let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
        setMovedState(true);

    }

    return (
        <React.Fragment>


            {((props.isDesktop == false) && (props.data != false)) &&
                <div className='section-slider'>
                    {props.data.sections.map((section) =>
                        <div className='section-item x' key={section.id}
                             onClick={()=>{

                                 window.location.href = "/catalog/"+section.id+"/";
                             }}

                             >
                                <span>{section.title}</span>
                        </div>
                    )}
                </div>
            }
            <div className='content wrapper'>
                <h3>Каталог</h3>
            </div>

            {props.data != false &&
                <React.Fragment>
                    {props.data.sections.map((section) =>
                        <div id={"section"+section.id} key={section.id} className='section'>
                            <div  className='header'>
                                {section.title}
                            </div>

                            <div className='section wrapper'>
                            {section.items.map((itemId) =>
                                <div key={itemId} className='element'>
                                    <Link className='product-preview' to={'/product/'+itemId+"/"}>
                                        <div className='product-labels'>
                                            {elements[itemId].labels?.map((label,index) => (
                                                <div className={'label label-'+label.type}>{label.title}</div>
                                            ))}
                                            {elements[itemId].sale &&
                                                <div className='catalog-item-label-sale'>Акция</div>
                                            }
                                        </div>
                                        <div className='preview-img-container'>
                                            <img className='preview-img' src={elements[itemId]?.photo[0]?.src} />
                                        </div>
                                    </Link>
                                    <div className='description'>
                                        <Link className='preview-title' to={'/product/'+itemId+"/"}>
                                            {elements[itemId].title.replace(/&quot;/g, '"')}
                                            <div className='weight'>{elements[itemId].weight}</div>
                                        </Link>
                                    </div>
                                        {elements[itemId].sku ? (
                                            <div className='buy-block'>
                                                от&nbsp;{Intl.NumberFormat("ru").format(elements[itemId].sku[0].price)}&nbsp;₽
                                                <Link className='btn btn-buy' to={'/product/'+itemId+"/"}>
                                                    Выбрать
                                                </Link>
                                            </div>
                                        ) : (
                                            <div className='buy-block'>
                                                <div className='price'>
                                                    {Intl.NumberFormat("ru").format(elements[itemId].price)}&nbsp;₽
                                                </div>
                                                {(props.basket[itemId]?.count > 0) ? (
                                                    <div className='block-count-buy'>
                                                        <button className='btn btn-buy' onClick={()=>props.onBasket(elements[itemId],false,(props.basket[itemId]?.count-1))}>-</button>
                                                        <div className='buy-count'>{props.basket[itemId]?.count}</div>
                                                        <button className='btn btn-buy' onClick={()=>props.onBasket(elements[itemId],false,(props.basket[itemId]?.count+1))}>+</button>
                                                    </div>
                                                ) : (
                                                    <button className='btn btn-buy' onClick={()=>props.onBasket(elements[itemId],false,1)}>Добавить</button>
                                                )
                                                }
                                            </div>
                                        )}



                                </div>

                            )}
                            </div>
                        </div>
                    )}
                </React.Fragment>
            }
        </React.Fragment>
    )
}


