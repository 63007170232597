import React from 'react';
import {Link} from "react-router-dom";
import {Slider} from "../../components/Slider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCheckSquare, faBasketShopping, faCoffee, faTruck, faBowlFood} from '@fortawesome/free-solid-svg-icons'
import {library} from "@fortawesome/fontawesome-svg-core";
import {ProductSlider} from "../../components/ProductSlider";
library.add(faCheckSquare, faBasketShopping, faCoffee,faTruck,faBowlFood)

export function Home(props) {

    const sliderData = [
        {
            'id' : 1,
            'type' : 'img',
            'src' : 'https://tundramarket.ru/images/catalog/banner1.jpg'
        },

    ]

    return (

            <div className='page-gap'>
            <Slider data={sliderData}/>
                <div className='wrapper'>
                <div className='home-text'>
                    <p>Удобная доставка полезных и вкусных продуктов отборного качества</p>
                    <Link className="btn btn-normal" to={'/about/'}>

                        О сервисе
                    </Link>
                    <Link className="btn btn-normal" to={'/delivery/'}>

                        Доставка
                    </Link>
                </div>
                </div>
            {props.data != false &&
                <div className='wrapper'>
                    <h4>Новинки</h4>
                    <ProductSlider items={props.data.elements} filter='new'/>

                    <h4>Каталог</h4>
                    <div className='section'>
                    {props.data.sections.map((section) =>
                            <div className='element'>
                                <Link to={'/catalog/'+section.id+"/"}>
                                    <img className="square" src={section.photo}/>
                                </Link>
                                {section.title}
                            </div>
                    )}
                    </div>
                </div>
            }
            </div>

    )
}