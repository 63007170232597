import './App.css';
import './desktop.css';
import {Route, Routes, useLocation, useNavigate} from "react-router";
import {Home} from "./modules/Home";
import {Catalog} from "./modules/Catalog";
import {useEffect, useState} from "react";
import {Basket} from "./modules/Basket";
import {Link} from "react-router-dom";
import {Delivery} from "./modules/Delivery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowLeft, faQuestion, faEnvelope, faBowlFood, faUser, faCheckSquare, faBasketShopping, faCoffee, faTruck, faMagnifyingGlass, faHouse  } from '@fortawesome/free-solid-svg-icons'
import {library} from "@fortawesome/fontawesome-svg-core";
import {Search} from "./modules/Search";
import {Personal} from "./modules/Personal";
import {CatalogDetail} from "./modules/CatalogDetail";
import {Order} from "./modules/Order";
import {Help} from "./modules/Help";
import {About} from "./modules/About";
import useWindowDimensions from "./hooks/useWindowDimensions";
import {HeaderMobile} from "./components/HeaderMobile";
import {HeaderDesktop} from "./components/HeaderDesktop";
import {FooterDesktop} from "./components/FooterDesktop";
import {PaymentDone} from "./modules/PaymentDone";
library.add(faArrowLeft, faQuestion, faEnvelope, faBowlFood, faUser, faCheckSquare, faBasketShopping, faCoffee,faTruck,faMagnifyingGlass, faHouse)

function App() {

    const { height, width } = useWindowDimensions();

    const [data, setData] = useState(false);
    const [basket, setBasket] = useState(localStorage.getItem('basket') ? JSON.parse(localStorage.getItem('basket')) : []);

    const location = useLocation();
    const navigate = useNavigate();
    const currentPage = window.location.pathname;

    useEffect(() => {
        console.log("use effect");
        if(data === false) {
            let url = 'https://tundramarket.ru/api/data/';
            fetch(url)
                .then(response => response.json())
                .then(dataRes => setData(dataRes));
        }
    });

    function onBasket(element, sku, count) {
        const uid = sku ? element.id+"#"+sku : element.id;

        const basketElement = {
            'count' : count,
            'sku' : sku,
            'element' : element
        }

        setBasket(prev => ({...prev, [uid]: basketElement}))



    }

    localStorage.setItem('basket',JSON.stringify(basket));
    console.dir(basket);

    console.log("data");
    console.dir(data);
    let basketCount = 0;// = Object.keys(basket).length;
    for( let key in basket ) {

        basketCount += (basket[key].count > 0) ? 1 : 0;
    }

    const isDesktop = (width > 920);

    if(isDesktop) {
        document.documentElement.style.setProperty('--column-count', 4);
    }

    return (
    <div id="App" className={isDesktop ? "App desktop" : "App"}>
      {isDesktop ?
          (<HeaderDesktop data={data} basketCount={basketCount}/>) : (<HeaderMobile />)
      }


      <div className='body container'>
          <Routes>
            <Route path='' element={<Home data={data} />} />
            <Route path='/catalog/' element={<Catalog isDesktop={isDesktop} data={data} basket={basket} onBasket={onBasket} />} />
            <Route path='/catalog/:sectionId/' element={<Catalog isDesktop={isDesktop} data={data} basket={basket} onBasket={onBasket} />} />
            <Route path='/product/:detailId/' element={<CatalogDetail onBasket={onBasket} data={data} basket={basket}/>} />
            <Route path='/basket/' element={<Basket data={data} basket={basket} onBasket={onBasket} />} />
            <Route path='/order/' element={<Order data={data} basket={basket} />} />
            <Route path='/search/' element={<Search data={data} />} />
            <Route path='/delivery/' element={<Delivery data={data} />} />
            <Route path='/personal/' element={<Personal data={data} />} />
            <Route path='/help/' element={<Help />} />
            <Route path='/about/' element={<About />} />
            <Route path='/payment/done/' element={<PaymentDone />} />
          </Routes>
      </div>
      {isDesktop ? (
          <FooterDesktop />
      ) : (
          <div className='bottom-nav'>
              <Link to='/' className={currentPage == '/' ? 'active' : ''}>
                  <FontAwesomeIcon icon={faHouse} />
                  <span>Главная</span>
              </Link>
              <Link to='/catalog/' className={currentPage.startsWith('/catalog/')  ? 'active' : ''}>
                  <FontAwesomeIcon icon={faBowlFood} />
                  <span>Каталог</span>
              </Link>
              <Link to='/search/' className={currentPage == '/search/' ? 'active' : ''}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                  <span>Поиск</span>
              </Link>
              <Link to='/basket/' className={currentPage == '/basket/' ? 'active' : ''}>
                  <FontAwesomeIcon icon={faBasketShopping} />
                  <span>Корзина
                      {(basketCount > 0) &&
                        <span className='nav-label-count'>{basketCount}</span>
                      }
                  </span>
              </Link>
              <Link to='/personal/' className={currentPage == '/personal/' ? 'active' : ''}>
                  <FontAwesomeIcon icon={faUser} />
                  <span>Кабинет</span>
              </Link>
          </div>
      )}

    </div>
  );
}

export default App;
