import React, {useState} from "react";
import {Link} from "react-router-dom";

/**
 * <input className={order.paymentType == "cash" ? 'active-payment' : 'payment'} value="Курьеру картой/наличными" readOnly={true} onClick={(e)=>updateOrder(prev => ({...prev, paymentType: "cash"}))} />
 *
 */

export function Order(props) {
    const deliverMsk = 299;
    const deliverMskOut = 490;
    const [orderSendSuccess, setOrderSendSuccess] = useState(false);
    const [order, updateOrder] = useState({
        'name' : '',
        'phone' : '',
        'phoneCorrect' : false,
        'deliveryArea' : 'msk',
        'deliveryPrice' : deliverMsk,
        'address' : '',
        'comment' : '',
        'paymentType' : 'card'
    });

    function updatePhone(event) {
        var v = event.target.value;

        if(v.length == 1) { v='7' }; // номер начинается только с 7
        if(v.length == 2) { v='79' }; // номер начинается только с 7

        v = v.replace(/\D+/g,""); // оставляем только цифры в строке

        if(v.length > 11) {
            v = v.substr(0,11);
        }

        const phoneCorrect = (v.length == 11) ? true : false;

        updateOrder(prev => ({...prev, phone: v, phoneCorrect: phoneCorrect}));
    }

    function sendOrder(phone,detail) {
        console.log("SendOrder!");
        const apiUrl = 'https://tundramarket.ru/api/order-send/';



        const data = {
            'order' : order,
            'basket' : props.basket
        }

        fetch(apiUrl,
        {
                /*mode: 'no-cors',*/
                method: 'POST',
                headers: new Headers(
                    {"Content-Type": "application/json",
                        "Accept":"application/json"}
                ),
                body: JSON.stringify(
                    data
                )
            }
        )
        .then((response) => response.json())
        .then((data) => {
            console.log('This is your data');
            console.dir(data);
            setOrderSendSuccess(true);
            /*if(data.success) {
                props.onCloseOrder();
            }*/
            updateOrder({
                'name' : '',
                'phone' : '',
                'phoneCorrect' : false,
                'deliveryArea' : 'msk',
                'deliveryPrice' : deliverMsk,
                'address' : '',
                'comment' : '',
                'paymentType' : 'cash',

            });

            if(data.payment !== false) {
                console.log("Start payment");
                console.dir(data);
                const paymentUrl = data.payment.confirmation.confirmation_url;
                console.log("redirect: "+paymentUrl);
                window.location.href = paymentUrl;
            }

        });
    }


    return (

        <div className='content wrapper'>
            <h3>Оформление заказа</h3>
            <div className='input-block'>
                <label>Как вас зовут?</label>
                <input value={order.name} onChange={(e)=>updateOrder(prev => ({...prev, name: e.target.value}))} />
            </div>
            <div className='input-block'>
                <label>Введите телефон </label>
                <input value={order.phone} onChange={updatePhone} />
                {(!order.phoneCorrect && order.phone.length > 0) &&
                    <label className='color-main'>Телефон введен некорректно или не полностью</label>
                }
            </div>
            <div className='input-block'>
                <label>Укажите адрес доставки</label>
                <input className={order.deliveryArea == "msk" ? 'active-payment' : 'payment'} value="Доставка в пределах МКАД" readOnly={true} onClick={(e)=>updateOrder(prev => ({...prev, deliveryPrice: deliverMsk,deliveryArea: 'msk' }))} />
                <input className={order.deliveryArea == "mskout" ? 'active-payment' : 'payment'} value="Доставка по Подмосковью" readOnly={true} onClick={(e)=>updateOrder(prev => ({...prev, deliveryPrice: deliverMskOut,deliveryArea: 'mskout'}))} />
            </div>
            <div className='input-block'>
                <input value={order.address} onChange={(e)=>updateOrder(prev => ({...prev, address: e.target.value}))}/>
            </div>
            <div className='input-block'>
                <label>Введите комментарий</label>
                <input value={order.comment} onChange={(e)=>updateOrder(prev => ({...prev, comment: e.target.value}))}/>
            </div>
            <h3>Способ оплаты</h3>
            <div className='input-block'>


                <input className={order.paymentType == "card" ? 'active-payment' : 'payment'} value="Картой онлайн" readOnly={true} onClick={(e)=>updateOrder(prev => ({...prev, paymentType: "card"}))} />
            </div>
            <div>
                Стоимость доставки {order.deliveryPrice}₽
            </div>
            {orderSendSuccess &&
                <div>Заказ успешно отправлен</div>
            }
            {order.phoneCorrect ? (
                <a onClick={sendOrder}  className='btn btn-order'>Отправить заказ</a>
            ) : (
                <div className='btn btn-order disabled'>Отправить заказ</div>
            )}

        </div>

    )
}