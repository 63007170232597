import {Link} from "react-router-dom";

export function Help(props) {
    return (
        <div className='delivery-content'>
            <div className='content wrapper flex-1'>
                <h3>Контакты и помощь</h3>
                <p>
                    Мы уделяем особое внимание качеству продукции и сервису. Нам важно становиться лучше и удобнее для вас. Возник вопрос? Есть предложение? Что-то не устроило? Сообщите нам! мы всегда на связи.
                </p>
                <div>
                    <b>ООО "Премиум Фуд"</b>

                </div>
                <div>
                    ИНН 5029278390
                </div>
                <p></p>
                <Link to='https://wa.me/79801837645?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!%20%D0%92%D0%BE%D0%BF%D1%80%D0%BE%D1%81%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0%20tundramarket.ru' className='contact-link'>WhatsApp</Link>
                <Link to='mailto:info@tundramarket.ru' className='contact-link'>E-mail</Link>


            </div>

        </div>

    )
}