import {Link} from "react-router-dom";

export function ProductSlider(props) {
    console.log("ProductSlider");
    console.dir(props);

    let elemets = [];
    Object.keys(props.items).forEach(function(key, index) {
        if(props.items[key][props.filter] === true) {
            elemets.push(props.items[key]);
        }

    });

    return (
        <div className='productSlider'>
            {elemets.map((element) =>
                <Link className='productSliderItem' to={"/product/"+element.id}>
                    <img src={element?.photo[0]?.src} />
                    <span>{element.title}</span>
                </Link>
            )}
        </div>
    )
}