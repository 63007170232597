import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import React from "react";

export function HeaderMobile(props) {

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className='page-header'>
            {(location.pathname !== '/') ? (
                <div className='page-header-nav' onClick={() => navigate(-1)} >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>
            ) : (
                <div className='page-header-nav'>

                </div>
            )
            }
            <Link className='logo-container' to='/'>

                <img src="https://tundramarket.ru/images/logotm.svg" />

            </Link>
            <Link className='page-header-nav' to='/help/'>
                <FontAwesomeIcon icon={faEnvelope} />
            </Link>

        </div>
    )
}