import React from 'react';
import {Link} from "react-router-dom";
import {Slider} from "../../components/Slider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCheckSquare, faBasketShopping, faCoffee, faTruck, faBowlFood} from '@fortawesome/free-solid-svg-icons'
import {library} from "@fortawesome/fontawesome-svg-core";
library.add(faCheckSquare, faBasketShopping, faCoffee,faTruck,faBowlFood)

export function About(props) {

    const sliderData = [
        {
            'id' : 1,
            'type' : 'img',
            'src' : 'https://tundramarket.ru/images/about.jpg'
        },

    ]

    return (

        <div className='page-gap about'>
            <Slider data={sliderData}/>
            <div className='content wrapper flex-1'>

                <p>
                Наш проект - это команда профессионалов, настоящих ремесленников которые любят
                свое дело, всегда работающих на достижение лучшего результата. Мы всегда стремимся
                показать уникальность нашего продукта.
                </p>
                <p>
                <b>Наша миссия.</b><br/>
                В наших деликатесах мы хотим передать чистый вкус продукта,
                чтобы вы могли в полной мере ощутить завораживающее волшебство тундры,
                через вкусовые, гастрономические впечатления.
                </p>
                <p>
                В нашем ассортименте присутствует деликатесы и продукты из оленины из разных оленеводческих хозяйств, со всех уголков нашего Заполярья.
                </p>
                <p>
                Для вас мы стараемся раскрыть всю многогранность гастрономических особенностей
                мяса северного оленя, а так же все уникальные свойства и качества этого продукта,
                которые по праву считаются непревзойденными.
                </p>
                <p>
                - Всю без исключения продукцию представленную в нашем ассортименте деликатесов и колбас из мяса северного оленя можно назвать "гастрономическим изыском".
                - В составе всех сыровяленых и сырокопченых колбас содержится только мясо северного оленя ( от 80 до 86 % в зависимости от сорта продукции ), жир олений, натуральные сушеные ягоды (например Брусника), натуральные экстракты ягод, только натуральные специи и пряности.
                - В производстве всех сортов данной продукции используются только натуральные специи лучших европейских производителей.
                </p>
                <p>
                - Сырокопченые и сыровяленые деликатесы , такие как - Окорок оленя
                сырокопченый, Чипсы из мяса оленя - по праву являются признанными и лучшими в своем сегменте деликатесами из мяса северного оленя. Для изготовления всей деликатесной продукции используется только оленина высшей категории.
                </p>
                <p>
                - Наряду с уже традиционными рецептурами, которым отдают предпочтение в наших северных регионах и к примеру странах Скандинавии, где мясо северного оленя так же считается непревзойденным по своим вкусовым качествам, специалисты нашего
                производства  удивляют и рецептурами традиционными для таких стран как Италия и Испания, весьма удачно интерпретируют такие популярнейшие, европейские сорта колбас как Карпаччо и Сальчичон - на северный манер, производя их из мяса северного оленя. Благодаря этому, данная продукция приобрела свой неповторимый
                вкусовой "северный" оттенок, что выгодно отличает данные деликатесы среди прочих.
                </p>
                <p>
                - В состав всех сортов сырокопченых и сыровяленых  колбас входит только мясо северного
                оленя,  олений жир, натуральные специи и пряности.  В таких сортах колбас как Сальчичон ( испанская рецептура ), Норд ( скандинавская рецептура ) в нужных пропорциях есть шпиг, свинина, придающие определенную "сочность" данным продуктам, согласно рецептурам стран - происхождения данных сортов колбас.
                </p>
                <p>
                - Все сырокопченые и сыровяленые деликатесы и колбасы из мяса северного оленя отличаются неповторимым, изысканным вкусом и ароматом.
                </p>
                <p>
                - Благодаря условиям содержания (вольный выпас), и особенностям питания, мясо северного оленя является самым
                экологически чистым и полезным для употребления в пищу.
                Зимой основной корм северного оленя - ягель, ветки, почки деревьев и кустарников.
                Летом - ягоды, листья и побеги растений, грибы.
                </p>
                <p>
                - Главное достоинство всех без исключения сырокопченых и сыровяленых колбас и деликатесов представленных в нашем ассортименте в том, что вся продукция производится только из высококачественного и экологически чистого мяса северного оленя.
                </p>
            </div>
        </div>

    )
}