import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faBasketShopping, faEnvelope, faUser} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import React from "react";

export function HeaderDesktop(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const currentPage = window.location.pathname;

    return (
        <div className="header-desktop-container container">
            <div className="header-desktop ">
                <a className="logo" href="/">
                    <img src="https://tundramarket.ru/images/logotm.svg" />

                </a>
                <div className="header-location">
                    <a href="/contacts/">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                            <path
                                d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                        </svg>
                        <span>Москва</span>
                    </a>
                </div>
                <div className="header-delivery-info">
                    Удобная доставка полезных и вкусных продуктов отбороного качества
                    <div className="header-delivery-links">
                        <a className="btn btn-normal" href="/about/">О сервисе</a>
                        <a className="btn btn-normal" href="/delivery/">Условия доставки</a>
                    </div>
                </div>
                <div className="flex-1"></div>
                <div className="basket">
                    <Link to='/basket/' className={currentPage == '/basket/' ? 'active' : ''}>
                        <FontAwesomeIcon icon={faBasketShopping} />
                        <span>Корзина
                            {(props.basketCount > 0) &&
                            <span className='nav-label-count'>{props.basketCount}</span>
                            }
                        </span>
                    </Link>
                    <Link to='/personal/' className={currentPage == '/personal/' ? 'active' : ''}>
                        <FontAwesomeIcon icon={faUser} />
                        <span>Кабинет</span>
                    </Link>
                </div>
                <div className="header-contacts socials">
                    <a target="_blank" className="whatsapp"
                       href="https://wa.me/79801837645?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!%20%D0%92%D0%BE%D0%BF%D1%80%D0%BE%D1%81%20%D1%81%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0%20tundramarket.ru">

                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                             className="bi bi-whatsapp" viewBox="0 0 16 16">
                            <path
                                d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
                        </svg>

                    </a>
                    <a target="_blank" className="telegram" href="https://t.me/79801837645">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                             className="bi bi-telegram" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"></path>
                        </svg>
                    </a>
                </div>
            </div>
            {props.data.sections &&
                <div className="main-menu">
                    {props.data.sections.map((section) =>

                        <Link className='item' to={'/catalog/'+section.id+"/"}>
                        {section.title}
                        </Link>
                    )}
                </div>
            }
        </div>

    )
}