import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import './style.css';
export function Slider(props) {

    const [marginLeft, setMarginLeft] = useState(0)
    const [touchStartX, setTouchStartX] = useState(0);
    const [slideActive, setSlideActive] = useState(1);
    function handleTouchStart(touchStartEvent) {

        const touch = touchStartEvent.touches[0];
        setTouchStartX(touch.pageX);

        console.dir(touchStartEvent);
    }
    function handleTouchMove(touchMoveEvent) {
        const touch = touchMoveEvent.touches[0];
        let x = touch.pageX-touchStartX;
        if(x > 60) {x = 60}
        if(x < -60) {x = -60}
        setMarginLeft(x);

        console.dir(touchMoveEvent);
    }
    function handleTouchEnd() {
        if(marginLeft < -30) {

        }
        if(marginLeft > 30) {

        };
        setMarginLeft(0)
    }

    let styles = {
        transform: `translateX(${marginLeft}px)`,
        zIndex: 10,
    }
    let prevStyles = {
        transform: `translateX(-100wv)`,
        zIndex: 1,
    }
    let nextStyles = {
        transform: `translateX(100wv)`,
        zIndex: 1,
    }
    let sliderStyle = {
        aspectRatio: `${props.aspectRatio ? props.aspectRatio : 2}`
    }
    return (
        <div className='slider'
             style={sliderStyle}
             onTouchStart={touchStartEvent => handleTouchStart(touchStartEvent)}
             onTouchMove={touchMoveEvent => handleTouchMove(touchMoveEvent)}
             onTouchEnd={() => handleTouchEnd()}
        >
            {props.data.map((slide) =>
                <div key={slide.id} className="slide" style={(slide.id == slideActive) ? styles : ((slide.id < slideActive) ? prevStyles : nextStyles )}>
                    <img src={slide.src} />
                </div>
            )}
        </div>
    )
}