import {Link} from "react-router-dom";
import React from "react";

export function Basket(props) {

    const basketMinPrice = 3000;
    const basketDeliveryPriceMsk = 299;
    const basketDeliveryPriceOutMKAD = 490;
    const basketFreeDeliveryFrom = 5000;

    let arBasket = [];
    let basketTotalPrice = 0;
    for(let key in props.basket) {
        if(props.basket[key].count  > 0) {
            props.basket[key].key = key;
            if(props.basket[key].sku) {
                console.dir(props.basket[key].element.sku);
                console.log("sku:"+props.basket[key].element.sku.length);

                for (let i = 0; i < props.basket[key].element.sku.length; i++) {
                    if(props.basket[key].element.sku[i].id == props.basket[key].sku) {
                        props.basket[key].element.price = props.basket[key].element.sku[i].price;
                        props.basket[key].element.sku_title = props.basket[key].element.sku[i].title;
                    }

                }
            }
            basketTotalPrice += props.basket[key].element.price * props.basket[key].count;
            arBasket.push(props.basket[key])
        }
    }

    return (
        <div>


        <div className='content wrapper'>
            <h3>Корзина</h3>
            <div className='basket'>
                {arBasket.map(item =>
                    <div key={item.key} className='basket-line'>
                        <div className='basket-line-preview'>
                            <img src={item.element.photo[0].src} />
                        </div>
                        <div className='basket-line-title'>
                            <Link to={'/product/'+item.element.id+'/'}>{item.element.title}</Link>
                            {item.element.sku_title ?
                                (
                                    <div>
                                        <b>{item.element?.sku_title}</b>
                                    </div>
                                ) : (
                                    <div>
                                        <b>{item.element?.weight}</b>
                                    </div>
                                )

                            }
                        </div>
                        <div  className='basket-line-count'>

                            <div className='block-count-buy'>
                                <button className='btn btn-buy' onClick={()=>props.onBasket(item.element,item.sku,(item.count-1))}>-</button>
                                <div className='buy-count'>{item.count}</div>
                                <button className='btn btn-buy' onClick={()=>props.onBasket(item.element,item.sku,(item.count+1))}>+</button>
                            </div>
                        </div>
                        <div  className='basket-line-price'>
                            {Intl.NumberFormat("ru").format(item.element.price*item.count)}&nbsp;₽
                        </div>
                    </div>
                )}
                <div className='basket-line'>
                    <div className='basket-line-title'>
                        Общая сумма
                    </div>
                    <div  className='basket-line-price'>
                    {Intl.NumberFormat("ru").format(basketTotalPrice)}&nbsp;₽
                    </div>
                </div>
                {basketTotalPrice < basketMinPrice &&
                    <div className='basket-line'>
                        <div className='basket-line-title'>
                            <div>Минимальная сумма заказа составляет</div>
                            <b>{Intl.NumberFormat("ru").format(basketMinPrice)}&nbsp;₽</b>
                            <div>Добавьте еще на <b>{Intl.NumberFormat("ru").format(basketMinPrice-basketTotalPrice)}&nbsp;₽</b></div>
                        </div>
                    </div>
                }
                {basketTotalPrice >= basketMinPrice &&
                    <React.Fragment>
                        {(basketTotalPrice >= basketFreeDeliveryFrom) ?
                            (
                                <div className='basket-line'>
                                    <div className='basket-line-title'>
                                        Доставка
                                    </div>
                                    <div  className='basket-line-price'>
                                        0&nbsp;₽
                                    </div>
                                </div>
                            ) : (
                                <React.Fragment>
                                    <div className='basket-line'>
                                        <div className='basket-line-title'>
                                            Доставка в пределах МКАД
                                        </div>
                                        <div  className='basket-line-price'>
                                            {basketDeliveryPriceMsk}&nbsp;₽
                                        </div>
                                    </div>
                                    <div className='basket-line'>
                                        <div className='basket-line-title'>
                                            Доставка по Подмосковью
                                        </div>
                                        <div  className='basket-line-price'>
                                            {basketDeliveryPriceOutMKAD}&nbsp;₽
                                        </div>
                                    </div>
                                </React.Fragment>

                            )
                        }
                    </React.Fragment>
                }
            </div>

        </div>
            {basketTotalPrice >= basketMinPrice &&
                <Link to='/order/' className='btn btn-order'>Оформить заказ</Link>
            }

        </div>
    )
}