import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Slider} from "../../components/Slider";

export function Search(props) {

    const [q, setQ] = useState('');

    let elements = props['data']['elements'];
    let result = [];
    console.dir(elements);
    if(q.length > 1) {
        for(let key in elements) {
            if(elements[key].title.toLowerCase().indexOf(q.toLowerCase()) >= 0) {
                result.push(elements[key]);
            }
        }
    }

    return (
        <div className='content wrapper'>
            <h3>Поиск</h3>
            <div className='input-block'>
                <input value={q} onChange={(e)=>setQ(e.target.value)} />
            </div>
            <div className='search-result'>
            {props.data != false &&
                <React.Fragment>
                    {((q.length > 1) && (result.length == 0)) &&
                        <div>Ничего не найдено</div>
                    }
                    {result.map(item =>
                        <div className='search-result-item'>
                            <img src={item.photo[0].src} />
                            <Link to={'/product/'+item.id+'/'}>{item.title}</Link>
                        </div>
                    )}
                </React.Fragment>
            }
            </div>
        </div>
    )
}


